/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the Link UI component.
 * @param {string} href - href link referring to, defaults to void,
 * @param {string} fs - font size of the link can be customised, defaults to 16px
 * @param {string} text - Link text
 * @param {Object} props - Other link styles
 * @returns {ReactElement} Link UI component.
 */

const LinkUI = ({ href = 'javacript;;', fs = '16px', text, ...props }) => {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <Link
        fontSize={fs}
        lineHeight="1.25"
        textAlign="left"
        textDecoration="underline"
        textUnderlineOffset="1px"
        color="#cf4520"
        {...props}
      >
        {text}
      </Link>
    </NextLink>
  );
};

export default LinkUI;
