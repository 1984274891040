/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Border component.
 * @param {string} vertical - if the border is vertical
 * @param {string} margin - give a little margin
 * @returns {ReactElement} Border component.
 */

const Border = ({ vertical, margin }) => {
  return (
    <Box
      width="100%"
      borderBottom="1px solid #d4c9b4"
      // border={vertical ? '1px solid pink' : 'none'}
      display="block"
      lineHeight="1px"
      position="relative"
      textAlign="center"
      // margin={margin ? margin : '10px 0'}
      transform={vertical ? 'rotate(90deg)' : null}
    />
  );
};

export default Border;
