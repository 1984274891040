/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
import Border from '@components/Elements/Border';
/* Components */

/* Services */

/**
 * Renders the Container Wrapper in /program-details page UI component.
 * @param {ReactElement} children - Children for this UI Wrapper,
 * @param {Object} borderProps - Props for border, to be sent as an Object like borderProps={{whatever}}
 * @param {Object} props - Other link styles
 * @returns {ReactElement} ContainerUI component.
 */

const ContainerUI = ({ children, borderProps, ...props }) => {
  return (
    <Box>
      <Box padding="20px 0" boxSizing="border-box" {...props}>
        {children}
      </Box>
      {props.hideBorder ? '' : <Border key={nanoid()} {...borderProps} />}
    </Box>
  );
};

export default ContainerUI;
