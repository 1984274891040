/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import parse from 'html-react-parser';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the Description Design component.
 * @param {string} text - This can be html escaped string or normal text.
 * @param {Boolean} canHaveHtml - defaults false. if the text coming can be an html make it true
 * @param {Object} props - extra styles
 * @returns {ReactElement} Description Design component.
 */

const DescriptionDesign = ({ text, canHaveHtml, ...props }) => {
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="16px"
      lineHeight="1.8"
      className="descriptionDesign"
      {...props}
    >
      {canHaveHtml ? parse(text?.replace('< / br>', '<br />').replace('< /br>', '<br />').replace('</br>', '<br />').replace(/\r\n/g, '<br />')) : text}
      <style global jsx>
        {`
          .descriptionDesign ul {
            margin: 5px 15px;
            padding: 0;
            line-height: 2em;
          }
          .descriptionDesign ul li {
            margin: 0 0 0 15px;
            padding: 0;
          }
        `}
      </style>
    </Box>
  );
};

export default DescriptionDesign;
